import MatomoTracker from "@jonkoops/matomo-tracker";
import { createInstance, MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

let _internalMatomoInstance: MatomoTracker | null = null;

export const createMatomoInstance = (urlBase: string, setDomains: string[]) => {
  const matomoInstance = createInstance({
    urlBase,
    siteId: 1,
    userId: undefined, // optional, default value: `undefined`.
    trackerUrl: "https://www.classdojo.com/ma/m.cd",
    srcUrl: "https://www.classdojo.com/ma/m.js",
    configurations: {
      setDomains,
    },
  });
  return matomoInstance;
};

/**
 * MatomoRouterProvider provides Matomo hooks for children components
 * and it tracks page views on location change.
 *
 * It should be embedded within `<Router>` so it has access to location.
 * Will only be instantiated if on production environment. Will pass through otherwise.
 */
export const MatomoRouterProvider = ({
  children,
  urlBase,
  domains,
  userId,
}: {
  children: JSX.Element;
  urlBase: string;
  domains: string[];
  userId?: string;
}) => {
  const isProduction = Config.nodeEnv == "production";

  // Matomo is throwing error on windows 10 devices, temporary until this is resolved: https://github.com/matomo-org/matomo/issues/22836
  const isWindows10Device = typeof window !== "undefined" && window.navigator.userAgent.includes("Windows NT 10");

  if (isProduction && !_internalMatomoInstance && !isWindows10Device) {
    _internalMatomoInstance = createMatomoInstance(urlBase, domains);
    _internalMatomoInstance?.pushInstruction("requireCookieConsent");
  }

  const location = useLocation();

  useEffect(() => {
    // track page view on each location change
    _internalMatomoInstance?.trackPageView();
  }, [location]);

  useEffect(() => {
    if (userId) {
      // update the userId sent by matomo every time it changes.
      _internalMatomoInstance?.pushInstruction("setUserId", userId);
    }
  }, [userId]);

  return _internalMatomoInstance ? (
    <MatomoProvider value={_internalMatomoInstance}>{children}</MatomoProvider>
  ) : (
    children
  );
};
